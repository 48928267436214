<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-11-06 14:41:03
-->
<template>
<el-header class="wk-header">
  <el-row :gutter="20" align="middle" style="padding-top: 8px;">
    <el-col :span="3" :offset="3" style="transform: translateY(3px)">
      <div class="logo-svg" @click="this.$router.push('/')"></div>
      <!-- <img src="./../assets/logo.png" @click="$router.push('/')"  class="header-logo" style="cursor: pointer;"> -->
    </el-col>
    <el-col :span="7">
      <el-row style="height:100%;text-align:center; " class="wk-taps">
        <el-col
          style="padding:0 !important; cursor: pointer;"
          v-for="item in tabs"
          :key="item.id"
          :span="6"
          :class="{active: item.id == currentIndex}"
          @click="handleChange(item)"
        >
          {{item.name}}
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="6" :offset="2" class="header-right">
      <el-input v-model="searchVal" placeholder="请输入搜索内容" class="mr20" :class="{ hideSearch : currentIndex == '5'}">
        <template #append>
          <search @click="handleSearch" style="width:1.2em; height:1.2em; color:white" />
        </template>
      </el-input>

      <template v-if="userInfo.portrait">
        <div  @click="$router.push('/mine')">
          <img :src="userInfo.portrait" :alt="userInfo.name" :title="userInfo.name" class="user-img">
        </div>
      </template>
      <template  v-else>
        <el-button type="primary"  @click="onShowDialogLogin">登录</el-button>
      </template>
    </el-col>
  </el-row>
</el-header>
<el-dialog
    v-model="showLogin"
    title=""
    width="350px"
    center
  >
    <!-- <img src="./../assets/logo.png" class="w100"> -->
      <div id="ewm">
      </div>
    <!-- <p class="gray mt10 center">请用微信扫码登录</p> -->
    <!-- <p class="mt10 center">
      <el-checkbox v-model="checked" >阅读并同意<span class="root-acti">《微客之家平台协议》</span></el-checkbox>
    </p> -->
  </el-dialog>

 <el-dialog
    v-model="showLoginOut"
    title="退出提示"
    width="350px"
    center
  >
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showLoginOut = false">取消</el-button>
        <el-button type="primary" @click="handleLoginOut"
          >退出</el-button
        >
      </span>
    </template>
  </el-dialog>

</template>

<script>
import {ElHeader, ElMessage, ElDialog} from 'element-plus'
import { ref } from 'vue'
import {postAction} from '@/utils/http'
import axios from  'axios'

export default {
  name: 'WKHeader',
  components: { ElHeader,ElDialog},
  setup() {
    const showLogin = ref(false)
    const checked =  ref(true)
    return {
      showLogin,
      checked
    }
  },
  data() {
    return {
      logo: './../assets/img/logo.webp',
      tabs: [
        {id: '1', name : '首页', path: '/'},
        {id: '2', name : '资讯', path: '/news'},
        {id: '3', name : '微客工具', path: '/tools'},
        {id: '4', name : '关于我们',path: '/about'},
      ],
      activeIndex: '1',
      searchVal: '',
      // showLogin: false
      //微信扫码登录
      appid:"wx682cabf1c07d56cb",
      appsecret:"b3c3acfcbd30b044a31c34008cc3fab9",
      userInfo:{
        id:'',
        openid:'',
        unionid:'',
        portrait:'',
        sex:'',
        name:''
      },
      showLoginOut: false,
      showNums:0
    }
  },
  props: {
    currentIndex: {
      type : Number,
      defualt : 1
    }
  },
  created(){
    if(this.$ls.get("wikeHome_current_userInfo")){
      this.userInfo = this.$ls.get("wikeHome_current_userInfo");
      this.$ls.set("wikeHome_current_userInfo",this.userInfo,28*60*60*1000);//设置有效时间 28天
    }
    //加载微信二维码
    this.getCode();
  },
  methods: {
    /**
     * @Description: 监听 tab 切换
     * @Author: Niklaus
     * @Param:
     * @Return:
     * @Date: 2021-11-06 15:38:06
     * @param {*} id
     */
    handleChange({path}) {
      this.$router.push(path)
    },

    handleSearch(){
      if(this.searchVal.trim() == '')
        ElMessage.info('请输入搜索内容')
      else
        this.$router.push({
          path: '/search',
          query: {
            val: this.searchVal
          }
        })
    },
    //展示登录框
    onShowDialogLogin(){
      this.showLogin = true;
      //获取微信登录二位码
      this.getCode();
    },
    //解析url
    getUrlParam(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
        var r = window.location.search.substr(1).match(reg)
        if (r != null) return unescape(r[2])
        return null
    },
    //获取微信登录二位码
    getCode(){
      let localUrl = window.location.href //回调url-本页
      let code = this.getUrlParam("code")
      if(code){
        //如果有接口调用凭证
        if(this.$ls.get("wikeHome_current_accessToken")
          &&this.$ls.get("wikeHome_current_openid")&&this.$ls.get("wikeHome_current_unionid")){
          //查询用户是否存在 1存在，登录成功 2不存在，注册，登录成功
          this.selectCheckUser(this.$ls.get("wikeHome_current_openid"),this.$ls.get("wikeHome_current_unionid"));
        }else if(this.$ls.get("wikeHome_current_refreshToken")
          &&this.$ls.get("wikeHome_current_openid")&&this.$ls.get("wikeHome_current_unionid")){
            //do 刷新 wikeHome_current_accessToken
            this.getRefreshToken(this.$ls.get("wikeHome_current_refreshToken"));
        }else{
            //do 获取AccessToken
            this.getAccessToken(code);
        }
        this.showLogin = false;
      }else{
        if(this.showLogin){
          this.$nextTick(()=>{
            new WxLogin({
              self_redirect:false,
              id:"ewm",
              appid:this.appid,
              scope:"snsapi_login",
              state:"zyzn_smlogin",
              redirect_uri:encodeURIComponent(localUrl)
            });
          });
        }
      }
    },
    //获取  access_token、refresh_token、openid、unionid
    getAccessToken(code){
      let accessUrl = "/wxauth/sns/oauth2/access_token?appid="+this.appid+"&secret="+this.appsecret+"&code=" + code
        + "&grant_type=authorization_code";
      axios.get(accessUrl).then(res=>{
        if(res.data.openid&&res.data.access_token&&res.data.refresh_token&&res.data.unionid){
          this.$ls.set("wikeHome_current_accessToken",res.data.access_token,50*60*1000);//接口调用凭证 设置有效时间 50分钟
          this.$ls.set("wikeHome_current_refreshToken",res.data.refresh_token,28*60*60*1000);//	用户刷新 设置有效时间 28天
          this.$ls.set("wikeHome_current_openid",res.data.openid,28*60*60*1000);//授权用户唯一标识 设置有效时间 28天
          this.$ls.set("wikeHome_current_unionid",res.data.unionid,28*60*60*1000);//设置有效时间 50分钟
          this.selectCheckUser(res.data.openid,res.data.unionid);
        }else{
          ElMessage({
            message: '获取access_token失败！',
            type: 'error',
          })
        }
      }).catch(err=>{
        console.log(JSON.stringify(err))
      });
    },
    //从数据库检索是否存在该用户
    selectCheckUser(openid,unionid){
      let obj={
        unionid:unionid,
        openid:openid,
        appId:this.appid
      }
      postAction("/sys/wbpt/queryUserWithUnionid",obj).then(res=>{
        if(res.retCode=="0"){
          if(res.external){
            this.userInfo = res.external;
            this.$ls.set("wikeHome_current_userInfo",res.external,28*60*60*1000);//设置有效时间 28天
            if(this.userInfo.name&&this.userInfo.portrait){
              ElMessage({
                message: '登录成功！',
                type: 'success',
              })
            }else{
              //微信获取用户详细信息,并注册
              this.getUserInfos(this.$ls.get("wikeHome_current_accessToken"),openid,unionid);
            }
          }else{
            //微信获取用户详细信息,并注册
            this.getUserInfos(this.$ls.get("wikeHome_current_accessToken"),openid,unionid);
          }
        }else{
          console.log(JSON.stringify(res))
        }
      }).catch(err=>{
        console.log(JSON.stringify(err))
      });
    },
    //微信获取用户详细信息
    getUserInfos(accessToken,openid,unionid){
      let userInfoUrl = "/wxauth/sns/userinfo?access_token="+accessToken+"&openid="+openid
      axios.get(userInfoUrl).then(res=>{
        if(res.data.openid){
          let obj = {
            appId:this.appid,
            openid:openid,
            unionid:unionid,
            name:res.data.nickname,
            sex:res.data.sex,
            portrait:res.data.headimgurl
          }
          postAction("/sys/wbpt/register",obj).then(res=>{
            if(res.retCode=="0"){
              ElMessage({
                message: '登录成功！',
                type: 'success',
              })
              this.userInfo = res.external;
              this.$ls.set("wikeHome_current_userInfo",res.external,28*60*60*1000);//设置有效时间 28天
            //返回 res.external tbs_user_external_weixin res.user sys_user
            }else{
              ElMessage({
                message: '登录失败，请稍后重试！',
                type: 'error',
              })
            }
          }).catch(err=>{
            console.log(JSON.stringify(err))
            });
        }else{
          console.log(JSON.stringify(res))
        }
      }).catch(err=>{
        console.log(JSON.stringify(err))
      });
    },
    //刷新accessToken
    getRefreshToken(wikeHome_current_refreshToken){
      let refreshTokenUrl = '/wxauth/sns/oauth2/refresh_token?appid='+this.appid+'&grant_type=refresh_token&refresh_token='
        +wikeHome_current_refreshToken;
       axios.get(refreshTokenUrl).then(res=>{
         if(res.data.access_token){
           this.$ls.set("wikeHome_current_accessToken",res.data.access_token,50*60*1000);//接口调用凭证 设置有效时间 50分钟
           this.$ls.set("wikeHome_current_openid",res.data.openid,28*60*60*1000);//授权用户唯一标识 设置有效时间 50分钟
           this.selectCheckUser(this.$ls.get("wikeHome_current_openid"),this.$ls.get("wikeHome_current_unionid"));
         }else{
           console.log("刷新accessToken失败");
            ElMessage({
              message: '登录失败，请稍后重试！',
              type: 'error',
            })
         }
       }).catch(err=>{
         console.log(JSON.stringify(err))
      });
    },
    //退出
    handleLoginOut(url){
      this.showLoginOut = false;
      this.$ls.remove("wikeHome_current_accessToken");//
      this.$ls.remove("wikeHome_current_refreshToken");//
      this.$ls.remove("wikeHome_current_openid");//
      this.$ls.remove("wikeHome_current_unionid");//
      this.$ls.remove("wikeHome_current_userInfo");//
      if(url){
        window.open(url,'_self');
      }else{
        // window.location.reload()
        //?code 开始的参数截取
        let localUrl = window.location.href //回调url-本页
        let n = localUrl.indexOf("?code");
        if(n>0){
          window.open(localUrl.substring(0,n),'_self');
        }else{
          window.open(localUrl,'_self');
        }
      }

    }
    //下一个方法

  }
}
</script>

<style lang="less" scoped>
.header-logo {
  // width: 75px;
  // height: auto;
  height: 25px;
  width: auto;
  transform: translateY(3px);
}
.active {
  color: #1E87FF;
  font-weight: bold;
  position: relative;
}
.active::after {
  content: '';
  position: absolute;
  bottom: -12px;
  display: block;
  width: 50%;
  margin-left: 25%;
  height: 5px;
  background-color: #1E87FF;
  border-radius: 10px;
  transition: 0.5s all ease-in;
}
.header-right {
  display: flex;
  justify-content: space-between;
}

.user-img{
  width:40px;
  height: 40px;
  border-radius: 50%;
}
.wk-taps {
  font-size: 1.2rem;
}
.hideSearch {
  visibility: hidden;
}
.logo-svg {
  cursor: pointer;
  width: 100px;
  height: 40px;
  background: url('./../assets/logo.svg') center / 100% 100% no-repeat;
}
</style>