<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-11-08 13:31:39
-->
<template>
  <el-footer height="130" class="footer">
    <el-row align="middle">
      <el-col :span="18" :offset="3">
        <div class="btm-fo">
          <p>主办单位：北京嘟聪文化传媒有限公司</p>
          <p class="middle"><a target="_blank" href=" https://beian.miit.gov.cn">京ICP备2021026150号</a></p>
          <p><a target="_blank" href="https://hiweke.bidsoon.cn/sys/common/static/tools/pic/2112091819_1639045194786.jpg">增值电信业务经营许可证京B2-20214283</a></p>
          <p class="last">工具入驻、资讯投稿：declan@hiweke.com</p>
        </div>
      </el-col>
    </el-row>
  </el-footer>

</template>

<script>
import { ElFooter } from 'element-plus'
export default {
  components: {
    ElFooter,
  },
  data() {
    return {
      unit: '主办单位：北京嘟聪文化传媒有限公司',
      ICP: '京ICP备2021026150号',
    }
  }
}
</script>

<style scoped>
  .el-col {
    position: relative;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    padding: 30px 0;
  }
  .footer-left {
    display: flex;
    display: -webkit-flex;
    align-items: center;
  }

  .footer {
    background-color: #1E1E1E;
    color: #A6A6A6;
  }
  .btm-fo {
    width: 100%;
  }
  .btm-fo  > p {
    float: left;
  }.btm-fo > p.last {
    float: right;
  }
  .btm-fo a {
    color: #A6A6A6;
}
.middle::before,
.middle::after {
  content: '';
  width: 1px;
  height: 1em;
  background-color: #ededed;
  display: inline-block;
  margin: 0 15px;
  transform: translateY(0.2em);
}

</style>
