/*
 * @Descripttion: 封装 axios
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-11-09 08:33:39
 */

import axios from  'axios'
import {
  // ElMessage,
  ElLoading
} from 'element-plus'
// import qs from  'qs'

let loadingInstance = null
/** 创建实例 */
const http = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 5000
})

/** 请求拦截器 **/
http.interceptors.request.use( config => {
  config.header = {
    'Content-Type': 'application/json',
    'X-Access-Token': ''
  }
  if(!(config.data && config.data.fromRoute == 'home')) {
    loadingInstance = ElLoading.service({
      lock: true,
      text: 'loading...'
    })
  }

  return config
}, error =>{
  Promise.reject(error)
})

http.interceptors.response.use( response => {
  if(loadingInstance)
    loadingInstance.close()
  if(response.status === 200) {
    return response.data
  }else {
    // ElMessage.error('请求错误')
    console.log('请求错误');
    return response.data
  }
}, error => {
  if(loadingInstance)
    loadingInstance.close()
  /***** 接收到异常响应的处理开始 *****/
  if (error && error.response) {
    // 1.公共错误处理
    // 2.根据响应码具体处理
    switch (error.response.status) {
      case 400:
        error.message = '错误请求'
        break;
      case 401:
        error.message = '未授权，请重新登录'
        break;
      case 403:
        error.message = '拒绝访问'
        break;
      case 404:
        error.message = '请求错误,未找到该资源'
        break;
      case 405:
        error.message = '请求方法未允许'
        break;
      case 408:
        error.message = '请求超时'
        break;
      case 500:
        error.message = '服务器端出错'
        break;
      case 501:
        error.message = '网络未实现'
        break;
      case 502:
        error.message = '网络错误'
        break;
      case 503:
        error.message = '服务不可用'
        break;
      case 504:
        error.message = '网络超时'
        break;
      case 505:
        error.message = 'http版本不支持该请求'
        break;
      default:
        error.message = `连接错误${error.response.status}`
    }
  } else {
    // 超时处理
    if (JSON.stringify(error).includes('timeout')) {
      error.message = '连接服务器失败服务器响应超时'
    }else
      error.message = '连接服务器失败'
  }
  console.log(error.message);
  // ElMessage.error(error.message)
  /***** 处理结束 *****/
  //如果不需要错误处理，以上的处理过程都可省略
  return Promise.resolve(error.response)
})

/** get 请求 */
const getAction = (url,params) => {
  const config = {
    method: 'get',
    url,
    params,
  }
  return http(config)
}

/** post请求 */
const postAction = (url,data) => {
  const config = {
    method: 'post',
    url,
    data,
  }
  return http(config)
}

const putAction = (url,data) => {
  const config = {
    method: 'get',
    url,
    data,
  }
  return http(config)
}

const delAction = (url, data) => {
  const config = {
    method: 'delete',
    url,
    data
  }
  return http(config)
}

export {
  getAction,
  postAction,
  putAction,
  delAction
}
